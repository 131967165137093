<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Lista de Servicios</h4>
          </template>
          <template v-slot:headerAction>
            <b-button
              v-user-can="'crear servicios'"
              variant="primary"
              @click="showModal('add', { icon: null, description: '' })"
            >
              Agregar
            </b-button>
          </template>
          <template v-slot:body>
            <DataTable
              v-if="services"
              :data="services"
              :columns="columns"
              :fields-search="['description']"
            >
              <template #rows="{ row, rowIndex }">
                <tr>
                  <th scope="row">{{ rowIndex + 1 }}</th>
                  <td>
                    <img :src="`${row.icon}`" style="width: 100px" />
                  </td>
                  <td>
                    {{ row.description }}
                  </td>
                  <td>
                    <b-button-group>
                      <b-button
                        v-user-can="'actualizar servicios'"
                        variant="warning"
                        size="sm"
                        @click="showModal('edit', row)"
                      >
                        <i class="ri-ball-pen-fill m-0"></i>
                      </b-button>
                      <b-button
                        v-user-can="'eliminar servicios'"
                        variant="danger"
                        size="sm"
                        @click="destroy(row.id)"
                      >
                        <i class="ri-delete-bin-line m-0"></i>
                      </b-button>
                    </b-button-group>
                  </td>
                </tr>
              </template>
            </DataTable>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <ModalForm @getData="getData" :type="type" :data="data" />
  </b-container>
</template>

<script>
import { Toast } from './../../../mixins/toast'
import { xray } from '../../../config/pluginInit'
import api from './../../../services/api'
import DataTable from './../../../components/DataTable.vue'
import ModalForm from './ModalForm.vue'
import Swal from 'sweetalert2'

export default {
  components: {
    ModalForm,
    DataTable
  },
  data () {
    return {
      data: {},
      type: 'add',
      services: [],
      columns: [
        {
          name: 'Icono',
          field: 'icon',
          sort: ''
        },
        {
          name: 'Descripción',
          field: 'description',
          sort: ''
        }
      ]
    }
  },
  mounted () {
    xray.index()
    this.getData()
  },
  methods: {
    async getData () {
      try {
        const { data } = await api.get('/services')
        this.services = data.data
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Error'
        })
      }
    },
    showModal (type, data) {
      this.data = {
        id: data.id,
        description: data.description
      }
      this.type = type
      this.$bvModal.show('modal-form')
    },
    destroy (id) {
      Swal.fire({
        icon: 'warning',
        title: 'Seguro de eliminar',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(`/services/${id}`)
            .then((res) => {
              if (res.status === 200) {
                Toast.fire({
                  icon: 'success',
                  title: 'Eliminado correctamente'
                })
                this.getData()
              }
            })
            .catch((error) => {
              Toast.fire({
                icon: 'error',
                title: error.message
              })
            })
        }
      })
    }
  }
}
</script>
